@import '~@angular/cdk/overlay-prebuilt.css'
@import '~@angular/cdk/text-field-prebuilt.css'

@import "reset"
@import "mixins"
@import "dialog"
@import "toast"
@import "table"

body
	background: $c-ameetee-gray
	color: $c-white-100
	font-family: 'Inter', sans-serif
	font-size: 16px
	line-height: 24px

input
	&:-webkit-autofill, &:-webkit-autofill:focus
		transition: background-color 600000s 0s, color 600000s 0s
	&[data-autocompleted]
		background-color: transparent !important

//for ngx-file-upload customClassName
.upload-file
	display: flex
	align-items: center
	border: 1px dashed $c-ameetee-gray
	border-radius: 8px
	width: 496px
	height: 88px
	padding: 24px 20px

	label
		color: $c-white-80

.highlight
	border: 1px solid $c-yellow
	border-radius: 8px

dl
	display: flex
	gap: 24px

	dt
		+content-m-400
		color: $c-white-60

	dd
		+subheaders-l-600
		color: $c-brand

.upload-logo__content
	color: rgba(255, 255, 255, 0.8)
	height: 130px
	width: 130px

.upload-logo_drop-zone
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 2

// logo-cropper styles	

.ngx-ic-overlay,
.ngx-ic-cropper
	outline: #1C1C1C !important

.ngx-ic-cropper.ngx-ic-round:after
	box-shadow: none !important

.ngx-ic-cropper:after
	border: solid 1px !important

.ngx-ic-move
	border: 2px solid #40BA00 !important
	background: #41ba0071 !important
